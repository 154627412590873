export const validateNIP = (nip) => {
    if (!/^\d{10}$/.test(nip)) return false

    if (typeof nip !== 'string') nip = `${nip}`

    const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
    const digits = nip.split('').map((digit => parseInt(digit)))
    const checksum = digits.slice(0, 9).reduce((sum, digit, index) => sum + digit * weights[index], 0)

    return checksum % 11 === digits[9]
}

class  NIPClass {
    constructor() {
    }

    validate(value) {
        if (!/^\d{10}$/.test(value)) return false

        if (typeof value !== 'string') value = `${value}`

        const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
        const digits = value.split('').map((digit => parseInt(digit)))
        const checksum = digits.slice(0, 9).reduce((sum, digit, index) => sum + digit * weights[index], 0)

        return checksum % 11 === digits[9]
    }
}

export const NIP = new NIPClass()